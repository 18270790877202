import "./App.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
// import Dashboard from "./components/dashboard/Dashboard";
import Dashboardfile from "./components/DashboardNew/DashboardFile";
import Login from "./components/Login/Login";
import Library from "./components/Library/Library";
import ChartMain from "./components/ChartWindow/ChartMain";
// import History from "./components/History/History";
import History from "./components/History-v2/HistoryBody";
import OnboardAvatar from "./components/Avatar/OnboardAvatar";
import Favorites from "./components/Favorites/Favorites";
// import RecentPrompts from "./components/Recent-Prompts/RecentPrompts";
import RecentPrompts from "./components/Recent-Prompts-v2/RecentPromptsBody";
import AzureAuth from "./components/Azure_auth/AzureAuth";
import Launch from "./components/Launch/Launch";
import Rolemainbody from "./components/Role/Rolemainbody";
import GPT from "./components/gpt/GPT";
import SourcePDFView from "./components/ChartWindow/SourcePDFView";
import MobileSignin from "./components/Mobile/MobileSignin";
import RecordsBody from "./components/Records/RecordsBody";

//

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import Register from "./components/Login/Register";
import ConversationBody from "./components/Conversation/ConversationBody";
import Profile from "./components/Profile/Profile";
import Tokenmenu from "./components/Token/Tokenmenu";
import UnderConstruction from "./components/Login/UnderConstruction";
Amplify.configure(awsExports);

//components path
// const Login = React.lazy(() => import("./components/Login/Login"));
// const Library = React.lazy(() => import("./components/Library/Library"));
// const ChartMain = React.lazy(() =>
//   import("./components/ChartWindow/ChartMain")
// );

const router = [
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  // {
  //   path: "/signin",
  //   element: <Login />,
  // },
  // {
  //   path: "/launch",
  //   element: <Launch />,
  // },
  {
    path: "/avatarhub/enterprise",
    element: <Library pagetype="enterprice" />,
  },
  {
    path: "/avatarhub/general",
    element: <Library pagetype="general" />,
  },
  {
    path: "/chat/:id",
    element: <ChartMain />,
  },
  // {
  //   path: "/chat/:id/vertexai",
  //   element: <ChartMain />,
  // },
  {
    path: "/chat/:id/:conversationId",
    element: <ChartMain />,
  },
  {
    path: "/dashboard",
    element: <Dashboardfile />,
  },

  // {
  //   path: "/dashboardfile",
  //   element: <Dashboardfile />,
  // },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/onboard_avatar",
    element: <OnboardAvatar />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/recent_prompts",
    element: <RecentPrompts />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  {
    path: "/role",
    element: <Rolemainbody />,
  },
  {
    path: "/gpt",
    element: <GPT />,
  },
  {
    path: "/pdf/:url",
    element: <SourcePDFView />,
  },
  {
    path: "/mobilesignin",
    element: <MobileSignin />,
  },
  {
    path: "/conversation",
    element: <ConversationBody />,
  },
  {
    path: "/conversation/:id",
    element: <ConversationBody />,
  },
  {
    path: "/conversation/:id/:conversationId",
    element: <ConversationBody />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/token",
    element: <Tokenmenu />,
  },
  {
    path: "/public-agent",
    element: <RecordsBody />,
  },
  {
    path: "/public-agent/:id",
    element: <RecordsBody />,
  },
];

const temp_router = [
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  // {
  //   path: "/signin",
  //   element: <Login />,
  // },
  // {
  //   path: "/launch",
  //   element: <Launch />,
  // },
  {
    path: "/avatarhub/enterprise",
    element: <Library pagetype="enterprice" />,
  },
  {
    path: "/avatarhub/general",
    element: <Library pagetype="general" />,
  },
  {
    path: "/chat/:id",
    element: <ChartMain />,
  },
  // {
  //   path: "/chat/:id/vertexai",
  //   element: <ChartMain />,
  // },
  {
    path: "/chat/:id/:conversationId",
    element: <ChartMain />,
  },
  {
    path: "/dashboard",
    element: <Dashboardfile />,
  },

  // {
  //   path: "/dashboardfile",
  //   element: <Dashboardfile />,
  // },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/onboard_avatar",
    element: <OnboardAvatar />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/recent_prompts",
    element: <RecentPrompts />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  // {
  //   path: "/role",
  //   element: <Rolemainbody />,
  // },
  {
    path: "/gpt",
    element: <GPT />,
  },
  {
    path: "/pdf/:url",
    element: <SourcePDFView />,
  },
  {
    path: "/mobilesignin",
    element: <MobileSignin />,
  },
  {
    path: "/conversation",
    element: <RecordsBody />,
  },
  {
    path: "/conversation/:id",
    element: <RecordsBody />,
  },
  {
    path: "/conversation/:id/:conversationId",
    element: <RecordsBody />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/token",
    element: <Tokenmenu />,
  },
  {
    path: "/public-agent",
    element: <RecordsBody />,
  },
  {
    path: "/public-agent/:id",
    element: <RecordsBody />,
  },
];

function App() {
  // document.documentElement.style.setProperty("--main-color", "#fff");

  const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
    : "";
  return (
    <div className="App">
      <BrowserRouter>
        {/* under construction */}
        {/* <>
          <Routes>
            <Route path="/" element={<UnderConstruction />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </> */}

        {userName ? (
          <>
            <Routes>
              {![
                "ilham_n_pratama@telkomsel.co.id",
                "Doni_B_Pritama@telkomsel.co.id",
                "tirtawid@amazon.id",
                "aristya_w_eriandi@telkomsel.co.id",
                "rizaldy_azhar@telkomsel.co.id",
                "deddy_hermawan@telkomsel.co.id",
              ].includes(userName)
                ? router.map((val) => (
                    <Route
                      path={val.path}
                      key={val.path}
                      element={val.element}
                    />
                  ))
                : temp_router.map((val) => (
                    <Route
                      path={val.path}
                      key={val.path}
                      element={val.element}
                    />
                  ))}
              <Route
                path="*"
                element={<Navigate replace to="/conversation" />}
              />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/azure-auth" element={<AzureAuth />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
