import { memo, useEffect, useState } from "react";
import { Col, Row, Button, Drawer, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import navLogo from "../../../assets/ui/Logo SVG.svg";
import { Icon } from "@iconify/react";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";
import { Link } from "react-router-dom";
import MentionInputField from "../Header/drop";

const SidemenuMobile = (props) => {
  const { offerstatus } = useSelector((state) => state.chat);
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const [drawerbottomopen, setdrawerbottomOpen] = useState(false);
  const onbottomClose = () => {
    setdrawerbottomOpen(false);
  };
  const handleClick = () => {
    setdrawerbottomOpen(true);
    setSidebarMenu(0);
  };
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  useEffect(() => {
    if (window.location.pathname === "/conversation") {
      setSidebarMenu(1);
    } else if (window.location.pathname === "/dashboard") {
      setSidebarMenu(2);
    } else if (window.location.pathname === "/history") {
      // setSidebarMenu(4);
      setSidebarMenu(0);
    } else if (window.location.pathname === "/recent_prompts") {
      setSidebarMenu(3);
    } else if (window.location.pathname === "/favorites") {
      setSidebarMenu(5);
    } else if (window.location.pathname === "/avatarhub/general") {
      setSidebarMenu(6);
    } else if (window.location.pathname === "/role") {
      // setSidebarMenu(7);
      {
        ![
          "ilham_n_pratama@telkomsel.co.id",
          "Doni_B_Pritama@telkomsel.co.id",
          "tirtawid@amazon.id",
          "aristya_w_eriandi@telkomsel.co.id","rizaldy_azhar@telkomsel.co.id","deddy_hermawan@telkomsel.co.id",
        ].includes(userName) ? (
          setSidebarMenu(0)
        ) : (
          <></>
        );
      }
    } else if (window.location.pathname === "/token") {
      // setSidebarMenu(8);
      setSidebarMenu(0);
    } else if (window.location.pathname === "/public-agent") {
      setSidebarMenu(9);
    } else if (window.location.pathname.startsWith("/public-agent")) {
      setSidebarMenu(9);
    } else if (drawerbottomopen) {
      setSidebarMenu(0);
    }
  }, [window.location.pathname, props]);
  return (
    <>
      <Row className="chat-bottom-part">
        <Col>
          <Link to="/conversation">
            <div className="chat-bottom-icon">
              <Icon
                icon="gg:profile"
                className={`DashboardNavicon ${
                  sidebarmenu == 1 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 1 ? "pink-icon" : ""
              }`}
            >
              Agent hub
            </div>
          </Link>
        </Col>
        <Col className={`${sidebarmenu == 3 ? "pink-icon" : ""}`}>
          <Link to="/recent_prompts">
            <div className="chat-bottom-icon">
              <IoShuffleOutline
                className={`DashboardNavicon ${
                  sidebarmenu == 3 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 3 ? "pink-icon" : ""
              }`}
            >
              {" "}
              Recent prompt
            </div>
          </Link>
        </Col>
        <Col className="bottom-nav-bar">
          <Link to="/history">
            <div className="chat-bottom-icon">
              <IoIosTimer
                className={`DashboardNavicon ${
                  sidebarmenu == 4 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 4 ? "pink-icon" : ""
              }`}
            >
              History
            </div>
          </Link>
        </Col>
        <Col className="bottom-nav-bar">
          <Link to="/role">
            <div className="chat-bottom-icon">
              <Icon
                icon="ant-design:tag-outlined"
                className={`DashboardNavicon ${
                  sidebarmenu == 7 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 7 ? "pink-icon" : ""
              }`}
            >
              Role
            </div>
          </Link>
        </Col>
        <Col className="bottom-nav-bar">
          <Link to="/token">
            <div className="chat-bottom-icon">
              <Icon
                icon="material-symbols:token-outline"
                className={`DashboardNavicon ${
                  sidebarmenu == 8 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 8 ? "pink-icon" : ""
              }`}
            >
              Credits
            </div>
          </Link>
        </Col>
        <Col>
          <Link to="/dashboard">
            <div className="chat-bottom-icon">
              <IoIosKeypad
                className={`DashboardNavicon ${
                  sidebarmenu == 2 ? "pink-icon" : ""
                }`}
              />
            </div>
            <div
              className={`chat-bottom-part-font ${
                sidebarmenu == 2 ? "pink-icon" : ""
              }`}
            >
              Dashboard
            </div>
          </Link>
        </Col>

        <Col onClick={() => handleClick()}>
          <div className="chat-bottom-icon">
            <Icon
              className={`DashboardNavicon ${
                sidebarmenu == 0 ? "pink-icon" : ""
              }`}
              icon="ph:dots-three-outline-fill"
            />
          </div>
          <div
            className={`chat-bottom-part-font ${
              sidebarmenu == 0 ? "pink-icon" : ""
            }`}
          >
            More
          </div>
        </Col>
      </Row>
      <div className="chat-mobile-navbar-draw">
        <Drawer
          className="chat-mobile-navbar-draw"
          placement="bottom"
          onClose={onbottomClose}
          open={drawerbottomopen}
        >
          <Row className="chat-mobile-navbar-close-button">
            <Col>
              <Button
                className="chat-mobile-navbar-close-button"
                onClick={() => onbottomClose()}
              ></Button>
            </Col>
          </Row>
          <br />
          <Row className="d-flex justify-content-center">
            <div>
              <MentionInputField />
            </div>
          </Row>
          <br />
          <Row gutter={[16, 16]} className="chat-bottom-navigation">
            <Col className="bottom-nav-bar-hide">
              <Link to="/history">
                <div>
                  <Button className="chat-bottom-navigation-button ">
                    <IoIosTimer
                      className={`DashboardNavicon ${
                        sidebarmenu == 4 ? "pink-icon" : ""
                      }`}
                    />
                  </Button>
                </div>
                <div
                  className={`chat-bottom-navigation-font ${
                    sidebarmenu == 4 ? "pink-icon" : ""
                  }`}
                >
                  History
                </div>
              </Link>
            </Col>
            {offerstatus && (
              <>
                <Col className="bottom-nav-bar-hide">
                  <Link to="/token">
                    <div>
                      <Button className="chat-bottom-navigation-button">
                        <Icon
                          icon="material-symbols:token-outline"
                          className={`DashboardNavicon ${
                            sidebarmenu == 8 ? "pink-icon" : ""
                          }`}
                        />
                      </Button>
                    </div>
                    <div
                      className={`chat-bottom-navigation-font ${
                        sidebarmenu == 8 ? "pink-icon" : ""
                      }`}
                    >
                      Credits
                    </div>
                  </Link>
                </Col>
                <Col className="bottom-nav-bar-hide">
                  <Link to="/role">
                    <div>
                      <Button className="chat-bottom-navigation-button">
                        <Icon
                          icon="ant-design:tag-outlined"
                          className={`DashboardNavicon ${
                            sidebarmenu == 7 ? "pink-icon" : ""
                          }`}
                        />
                      </Button>
                    </div>
                    <div
                      className={`chat-bottom-navigation-font ${
                        sidebarmenu == 7 ? "pink-icon" : ""
                      }`}
                    >
                      Role
                    </div>
                  </Link>
                </Col>
              </>
            )}
          </Row>
        </Drawer>
      </div>
    </>
  );
};
export default SidemenuMobile;
