import React from "react";
import { Collapse, Col } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/record.css";
import AvatarList from "../../Conversation/Desktop/AvatarList";
import { publiclistAvatar } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";

const Privatepodlist = () => {
  const dispatch = useDispatch();
  const [podlist, setPodList] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  // const [records, setRecords] = useState([]);
  // const [notpinnedavatar, setNotPinnedAvatar] = useState([]);
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  useEffect(() => {
    const input = {
      event_type: "public_avatar_details",
    };
    setPreloaderStatus(true);
    dispatch(publiclistAvatar(input))
      .unwrap()
      .then(({ data }) => {
        if (JSON.parse(data.aivolvex_control_plane).length > 0) {
          console.log(JSON.parse(data.aivolvex_control_plane));
          const value = [
            "ilham_n_pratama@telkomsel.co.id",
            "Doni_B_Pritama@telkomsel.co.id",
            "tirtawid@amazon.id",
            "aristya_w_eriandi@telkomsel.co.id",
            "rizaldy_azhar@telkomsel.co.id",
            "deddy_hermawan@telkomsel.co.id",
          ].includes(userName)
            ? JSON.parse(data.aivolvex_control_plane).filter(
                (item) => item.avatar_name === "Telkomsel "
              )
            : JSON.parse(data.aivolvex_control_plane);
          // console.log(value);
          const filteredList = value.filter(
            (item) => item.id !== 742 && item.id !== 743
          ); //hide livecall analytics
          // console.log(filteredList);
          var all_pod_list = filteredList.map((v) => v.pod); //hide livecall analytics
          const pod = new Set([...all_pod_list]);
          // setRecords(value);
          const item_list = [...pod].map((pod_name, index) => {
            return {
              key: index,
              label: pod_name,
              children: (
                <AvatarList
                  avatarlist={filteredList.filter((v, index) => v.pod === pod_name)} //hide livecall analytics
                />
              ),
            };
          });
          setPreloaderStatus(false);
          setPodList(item_list);
          // setNotPinnedAvatar(item_list);
        } else {
          setPodList([]);
          // setRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const items = podlist;
  // console.log(podlist)
  const filteredList = podlist.filter((item) => item.key !== 1);
  // console.log(filteredList);
  // const items = filteredList;
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <div className="dashboard-board-private">
          <Collapse items={items} expandIconPosition="right" />
        </div>
      )}
    </>
  );
};
export default memo(Privatepodlist);
